<template>
  <b-card class="mb-4 pt-4" header-tag="header">
    <template #header>
      <b-row>
        <b-col cols="10">
          <h5 class="mb-0">{{$route.name}}</h5>
        </b-col>
        <b-col cols="2" class="text-right">
          <b-button-group class="add-button-group">
            <b-btn variant="primary" href="#/master-data/hotel/create">Tambah Data</b-btn>
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-row class="mb-3" style="margin-top: -20px;">
      <b-col cols="12">
        <b-form @submit.prevent="doFilter">
          <h6 class="mb-1" style="font-size: 1rem;">Filter Data</h6>
          <b-row class="filter-row">
            <b-col cols="4">
              <form-group-input ref="filter-name" type="text" placeholder="" :need-label="true" label="Nama Hotel" v-model="filter.name" id="filter-name" />
            </b-col>
            <b-col cols="4">
              <form-group-input ref="filter-city" type="text" placeholder="" :need-label="true" label="Nama Kota" v-model="filter.city" id="filter-city" />
            </b-col>
            <!-- <b-col cols="4">
            <b-form-group>
                <label for="" style="font-size:13px ;">Kota</label>
                <b-form-select v-model="filter.city" :options="options.city" style="position: relative;top: -7px;"></b-form-select>
              </b-form-group>
            </b-col> -->
            <b-col cols="3">
              <label class="form-label">&nbsp;</label><br>
              <b-btn type="submit" variant="primary">Cari</b-btn>
              <b-btn type="button" @click.prevent="clearForm" variant="warning text-white" class="ml-3">Clear</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <paginated-table ref="dataTable" aksi-dot :delete-reason="false" :can-delete="true" :can-edit="true" :data-url="apiUrl.hotel" :data-columns="columns" :dataParams="dataParams" :defaultDataParams="this.filter">
    </paginated-table>
  </b-card>
</template>

<script>
// import { objectToOptions, getYears, formatModelValue } from '@/_helpers'
import paginatedTable from '@/components/PaginatedTable.vue'
import formGroupInput from '@/components/FormGroupInput.vue'
import Hotel from '@/models/Hotel.js'

const hotelModel = new Hotel()

export default {
  components: {
    formGroupInput,
    paginatedTable
  },
  data () {
    return {
      isLoading: false,
      apiUrl: {
        hotel: hotelModel.endpoint
      },
      columns: [
        {
          prop: 'name',
          label: 'Nama',
          sortable: true,
          resizable: true,
          minWidth: 200
        },
        {
          prop: 'city',
          label: 'Kota',
          resizable: true,
          sortable: true,
          minWidth: 150
        },
        {
          prop: 'star',
          label: 'Rate',
          resizable: true,
          sortable: true,
          minWidth: 150
        },
        {
          prop: 'is_active',
          label: 'Status',
          isToggle: true,
          resizable: true,
          sortable: true,
          minWidth: 150,
          align: 'right'
        },
      ],
      options: {
        city: [
          { value: "Kota1", text: "Makkah" },
          { value: "Kota2", text: "Madinah" },
        ],
      },
      filter: {
        name: typeof this.$route.query.name !== 'undefined' ? this.$route.query.name : null
      },
      dataParams: ['name', 'city', 'kota']
    }
  },
  beforeMount () {
    this.$router.push({query: {...this.$route.query}}).catch(err => {})
  },
  mounted () {
  },
  methods: {
    clearForm () {
      this.$nextTick(() => {
        this.filter = {
          name: null
        }
        setTimeout(() => {
          this.doFilter()
        }, 100)
      })
    },
    doFilter () {
      this.$refs.dataTable.doFilter(this.filter)
    }
  }
}
</script>